import { taxHarvesterFeatureId } from '@components/TaxHarvester/helper/constants';
import { taxOptimiserFeatureId } from '@components/TaxOptimiser/helpers/constants';
import { isValidResponseEntity } from '@helpers/utils';
import useLocalStorage from '@hooks/useLocalStorage';
import { getAuth } from '@services/identity.service';
import { getFeatureUserByFeatureIdAndEntityId } from '@services/tax-optimiser.service';
import { useEffect } from 'react';

const useTaxOptimisationEligibility = () => {
    const taxOptimiserEligibilityKey = 'isEligibleForTaxOptimiser';
    const taxHarvesterEligibilityKey = 'isEligibleForTaxHarvester';
    const [isEligibleForTaxOptimiser, setIsEligibleForTaxOptimiser] =
        useLocalStorage(taxOptimiserEligibilityKey, null);
    const [isEligibleForTaxHarvester, setIsEligibleForTaxHarvester] =
        useLocalStorage(taxHarvesterEligibilityKey, null);
    const auth = getAuth();

    const fetcher = async (id, featureId) => {
        if (!auth && !id) return false;
        try {
            // const userEligibilityResponse =
            //     await getFeatureUserByFeatureIdAndEntityId(id || auth.id, featureId);
            // if (isValidResponseEntity(userEligibilityResponse)) {
            //     return userEligibilityResponse.entity.isUserEligibleForFeature;
            // }
            return true;
        } catch (error) {
            return false;
        }
    };
    

    const checkEligibility = async (id = null) => {
        if (!auth && !id) return null;
        try {
            if (isEligibleForTaxOptimiser === null || id) {
                const optimiserResponse = await fetcher(id, taxOptimiserFeatureId);
                const isOptimiserEligible = !!optimiserResponse;
                setIsEligibleForTaxOptimiser(isOptimiserEligible);
            }
            if (isEligibleForTaxHarvester === null || id) {
                const harvesterResponse = await fetcher(id, taxHarvesterFeatureId);
                const isHarvesterEligible = !!harvesterResponse;
                setIsEligibleForTaxHarvester(isHarvesterEligible);
            }
        } catch (error) {
            setIsEligibleForTaxOptimiser(false);
            setIsEligibleForTaxHarvester(false);
        }
    };

    useEffect(() => {
        checkEligibility();
    }, []);

    return { 
        isEligibleForTaxProducts: isEligibleForTaxOptimiser || isEligibleForTaxHarvester, 
        checkEligibility 
    };
};

export default useTaxOptimisationEligibility;
