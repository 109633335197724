import { GetAllApiFilterType } from '@components/Admin/TaxOptimiser/helpers/constants';
import { UrlParamsReplace } from './index';

export const TAX_OPTIMISER_QUESTIONNAIRES = () => {
    return UrlParamsReplace('/tax-optimiser-questionnaires');
};

export const GET_TAX_OPTIMISER_RESULTS = (entityId) => {
    return UrlParamsReplace('/tax-optimiser-results?entityId=:entityId', {
        entityId,
    });
};

export const TAX_OPTIMISER_MEETINGS = () => {
    return UrlParamsReplace('/tax-optimiser-meetings');
};

export const GET_FEATURE_USER = (entityId, featureId) => {
    return UrlParamsReplace('/feature-users/:entityId/feature/:featureId', {
        entityId,
        featureId,
    });
};

export const UPDATE_TAX_OPTIMISER_RESULTS = (id) => {
    return UrlParamsReplace('/tax-optimiser-results/:id', {
        id,
    });
};

export const GET_ALL_TAX_OPTIMISER_RESULTS_ADMIN = ({
    filterType,
    meetingStage = null,
    mobile = null,
    name = null,
    email = null,
    page = 0,
    limit = 0,
}) => {
    if (filterType === GetAllApiFilterType.MEETING) {
        return UrlParamsReplace(
            '/admin/get-all-tax-optimiser-results?filterType=:filterType&meetingStage=:meetingStage&page=:page&limit=:limit',
            {
                filterType,
                meetingStage,
                page,
                limit,
            }
        );
    }
    let url = UrlParamsReplace(
        '/admin/get-all-tax-optimiser-results?filterType=:filterType',
        {
            filterType,
        }
    );
    let queryParams = '';
    if (mobile) queryParams = `mobile=${mobile}`;
    if (email) queryParams = `email=${email}`;
    if (name) queryParams = `name=${name}`;

    if (queryParams.length) {
        url = `${url}&${queryParams}`;
    }

    return url;
};

export const GET_INVESTOR_TAX_OPTIMISER_RESULT_ADMIN = (investorId) => {
    return UrlParamsReplace(
        '/admin/get-investor-tax-optimiser-result-details?investorId=:investorId',
        {
            investorId,
        }
    );
};

export const TAX_OPTIMISER_MEETINGS_ADMIN = () => {
    return UrlParamsReplace('/admin/tax-optimiser-meetings');
};

export const UPLOAD_TAX_OPTIMISER_RESULTS = (entityId) =>
    UrlParamsReplace('/admin/tax-optimiser-results/:entityId/upload', {
        entityId,
    });

export const APPROVE_TAX_OPTIMISER_RESULTS = (taxOptimiserResultId, type) =>
    UrlParamsReplace(
        '/admin/tax-optimiser-results/:taxOptimiserResultId/approve?type=:type',
        {
            taxOptimiserResultId,
            type,
        }
    );

export const UPLOAD_TAX_OPTIMISER_ITR_PDF = (entityId) =>
    UrlParamsReplace('/tax-optimiser-results/:entityId/upload-tax-itr-pdf', {
        entityId,
    });

export const UPLOAD_TAX_OPTIMISER_ITR_PDF_ADMIN = (entityId) =>
    UrlParamsReplace(
        '/admin/tax-optimiser-results/:entityId/upload-tax-itr-pdf',
        {
            entityId,
        }
    );

export const RESCHEDULE_TAX_OPTIMISER_MEETINGS = () => {
    return UrlParamsReplace('/tax-optimiser-meetings/re-schedule');
};

export const RESCHEDULE_TAX_OPTIMISER_MEETINGS_ADMIN = (type) => {
    return UrlParamsReplace(
        '/admin/tax-optimiser-meetings/re-schedule?type=:type',
        {
            type,
        }
    );
};

export const OFFLINE_TAX_OPTIMISER_MEETING_ADMIN = () =>
    UrlParamsReplace('/admin/tax-optimiser-meetings/re-schedule/offline');

export const CANCEL_TAX_OPTIMISER_MEETING_ADMIN = (
    taxOptimiserResultId,
    type
) =>
    UrlParamsReplace(
        '/admin/tax-optimiser-meetings/cancel/tax-optimiser-result/:taxOptimiserResultId?type=:type',
        {
            taxOptimiserResultId,
            type,
        }
    );

export const GET_FEATURE_DETAILS_URL = (featureId) => {
    return UrlParamsReplace('/features/:featureId', {
        featureId,
    });
};

export const GET_PAYMENT_STATUS_URL = (entityId, entityType, featureId) => {
    return UrlParamsReplace('/subscription-payments/status?featureId=:featureId&entityId=:entityId&entityType=:entityType', {
        featureId,
        entityId,
        entityType
    });
};

export const CREATE_TAX_HARVESTER_PAYMENT_URL = () => {
    return UrlParamsReplace('/subscription-payments');
};

export const RETRY_TAX_HARVESTER_PAYMENT_URL = () => {
    return UrlParamsReplace(`/subscription-payments/retry`);
};