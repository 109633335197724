import useTaxOptimisationEligibility from '@hooks/functional/useTaxOptimisationEligibility';
import useNavItems from 'hooks/useNavItems';
import {
    Children,
    cloneElement,
    createContext,
    isValidElement,
    useContext,
} from 'react';

const InvestorContext = createContext();
export const useInvestorContext = () => useContext(InvestorContext);

/**
 * investorCache : get/update flags/static data according to user
 * can be used in whole app
 * EG: kyc status, wallet active, rm details
 * EG: investorCache = {
 * 				  "isWalletActive": true,
 * 				  "isKycVerified": true,
 * 				  "showReferral": true,
 * 				  "showJiraafSafe": true,
 *                "hasWalletBalance": true,
 * 				  "kycStatus": "Verified",
 * 				  "isDematPresent": true,
 * 				  "isNri": false,
 * 				  "van": "1234",
 * 				  "rmDetails": {
 * 				    "name": "Archana M",
 * 				    "email": "archana.b@sss.com",
 * 				    "mobile": "12222222",
 * 				    "image": "https://team-members-jiraaf.s3.ap-south-1.amazonaws.com/relationship-manager/70000059198.webp"
 * 				  }
 * 				}
 */

/**
 * InvestorContextProvider : metadata for investor, that can be used in whole app
 * having getters and setters
 * can be used to get/update filtered menu according to user
 * get/update investorCache
 * get investor/wallet details
 */
const InvestorContextProvider = ({children, ...pageProps}) => {
    const {
        menu = [],
        investorCache = null,
        menuLoading = false,
        setMenus = () => {},
        getInvestorDetails = () => {},
        getWalletDetails = () => {},
        getFTiDetails = () => {},
        updateNriCache = () => {},
        updateDematCache = () => {},
        reloadAuthRefsInHeader = () => {}
    } = useNavItems();
    const { isEligibleForTaxProducts, checkEligibility } = useTaxOptimisationEligibility();
    const childrenWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, {
                ...pageProps,
            });
        }
        return child;
    });
    return (
        <InvestorContext.Provider
            value={{
                menu,
                investorCache,
                menuLoading: menuLoading,
                isEligibleForTaxProducts,
                setMenus,
                getInvestorDetails,
                getWalletDetails,
                getFTiDetails,
                updateNriCache,
                updateDematCache,
                reloadAuthRefsInHeader,
                checkEligibility
            }}
        >
            {childrenWithProps}
        </InvestorContext.Provider>
    );
};

export default InvestorContextProvider;
