import * as CsrHttpService from '@http/csr-http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import {
    TAX_OPTIMISER_QUESTIONNAIRES,
    GET_TAX_OPTIMISER_RESULTS,
    TAX_OPTIMISER_MEETINGS,
    GET_FEATURE_USER,
    UPDATE_TAX_OPTIMISER_RESULTS,
    GET_ALL_TAX_OPTIMISER_RESULTS_ADMIN,
    GET_INVESTOR_TAX_OPTIMISER_RESULT_ADMIN,
    TAX_OPTIMISER_MEETINGS_ADMIN,
    UPLOAD_TAX_OPTIMISER_RESULTS,
    APPROVE_TAX_OPTIMISER_RESULTS,
    RESCHEDULE_TAX_OPTIMISER_MEETINGS_ADMIN,
    UPLOAD_TAX_OPTIMISER_ITR_PDF,
    UPLOAD_TAX_OPTIMISER_ITR_PDF_ADMIN,
    OFFLINE_TAX_OPTIMISER_MEETING_ADMIN,
    CANCEL_TAX_OPTIMISER_MEETING_ADMIN,
    RESCHEDULE_TAX_OPTIMISER_MEETINGS,
    GET_FEATURE_DETAILS_URL,
    GET_PAYMENT_STATUS_URL,
    CREATE_TAX_HARVESTER_PAYMENT_URL,
    RETRY_TAX_HARVESTER_PAYMENT_URL,
} from '@services/urls/taxOptimiser';
import { hasWindow } from '@services/http.service';

export const createTaxOptimiserQuestionnaire = (body, ctx = null) => {
    const url = TAX_OPTIMISER_QUESTIONNAIRES();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export const getTaxOptimiserResult = (entityId, ctx = null) => {
    const url = GET_TAX_OPTIMISER_RESULTS(entityId);

    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createTaxOptimiserMeeting = (body, ctx = null) => {
    const url = TAX_OPTIMISER_MEETINGS();

    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export const getFeatureUserByFeatureIdAndEntityId = (
    entityId,
    featureId,
    ctx = null
) => {
    const url = GET_FEATURE_USER(entityId, featureId);

    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const updateTaxOptimiserResult = (id, details, ctx = null) => {
    const url = UPDATE_TAX_OPTIMISER_RESULTS(id);

    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, details);
    }
    return SsrHttpService.putWithAuth(url, details, ctx);
};

export const getAllTaxOptimiserResultsAdmin = (params, ctx = null) => {
    const url = GET_ALL_TAX_OPTIMISER_RESULTS_ADMIN(params);

    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const getInvestorTaxOptimiserResultsAdmin = (investorId, ctx = null) => {
    const url = GET_INVESTOR_TAX_OPTIMISER_RESULT_ADMIN(investorId);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createTaxOptimiserMeetingAdmin = (body, ctx = null) => {
    const url = TAX_OPTIMISER_MEETINGS_ADMIN();

    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export const uploadTaxOptimiserResults = (entityId, file, ctx = null) => {
    const url = UPLOAD_TAX_OPTIMISER_RESULTS(entityId);
    const formData = new FormData();
    formData.append('file', file);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, formData);
    }
    return SsrHttpService.postWithAuth(url, formData, ctx);
};

export const approveTaxOptimiserResults = (
    taxOptimiserResultId,
    type,
    ctx = null
) => {
    const url = APPROVE_TAX_OPTIMISER_RESULTS(taxOptimiserResultId, type);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url);
    }
    return SsrHttpService.postWithAuth(url, ctx);
};

export const uploadTaxOptimiserItrPdf = (entityId, file, ctx = null) => {
    const url = UPLOAD_TAX_OPTIMISER_ITR_PDF(entityId);
    const formData = new FormData();
    formData.append('file', file);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, formData);
    }
    return SsrHttpService.putWithAuth(url, formData, ctx);
};

export const uploadTaxOptimiserItrPdfAdmin = (entityId, file, ctx = null) => {
    const url = UPLOAD_TAX_OPTIMISER_ITR_PDF_ADMIN(entityId);
    const formData = new FormData();
    formData.append('file', file);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, formData);
    }
    return SsrHttpService.putWithAuth(url, formData, ctx);
};

export const rescheduleTaxOptimiserMeeting = (body, ctx = null) => {
    const url = RESCHEDULE_TAX_OPTIMISER_MEETINGS();

    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export const rescheduleTaxOptimiserMeetingAdmin = (body, type, ctx = null) => {
    const url = RESCHEDULE_TAX_OPTIMISER_MEETINGS_ADMIN(type);

    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export const offlineTaxOptimiserMeetingAdmin = (body, ctx = null) => {
    const url = OFFLINE_TAX_OPTIMISER_MEETING_ADMIN();
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export const cancelTaxOptimiserMeetingAdmin = (taxOptimiserResultId, type, ctx = null) => {
    const url = CANCEL_TAX_OPTIMISER_MEETING_ADMIN(taxOptimiserResultId, type);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url);
    }
    return SsrHttpService.putWithAuth(url, ctx);
};

export const getFeatureDetails = (
    featureId,
    ctx = null
) => {
    const url = GET_FEATURE_DETAILS_URL(featureId);

    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url, ctx);
};

export const getHarvesterPaymentStatus = (
    entityId,
    entityType,
    featureId,
    ctx = null
) => {
    const url = GET_PAYMENT_STATUS_URL(entityId, entityType, featureId);

    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createTaxHarvesterPaymentRequest = (body, ctx = null) => {
    const url = CREATE_TAX_HARVESTER_PAYMENT_URL(body);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export const retryTaxHarvesterPaymentRequest = (body, ctx = null) => {
    const url = RETRY_TAX_HARVESTER_PAYMENT_URL(body);
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};