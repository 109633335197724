import { envsEnum } from "@helpers/constants";

export const GetAllApiFilterType = Object.freeze({
    MEETING: 'meeting',
    SEARCH: 'search',
});

export const GetInvestorTaxOptimiserResultDetailsWorkflow = Object.freeze({
    INVITED: 'Invited',
    QA_ANSWERED: 'Q&A Answered',
    CALL_ONE_SCHEDULED: 'Call 1 Scheduled',
    CALL_TWO_SCHEDULED: 'Call 2 Scheduled',
    MISSED: 'Missed',
    CALL_ONE_COMPLETED: 'Call 1 Completed',
    CALL_TWO_COMPLETED: 'Call 2 Completed',
    FINAL_PDF_SHARED: 'Final Pdf Shared',
    CONSULTATION_COMPLETED: 'Consultation completed'
});

export const TaxMeetingState = Object.freeze({
    SCHEDULED: 'Scheduled',
    NO_SHOW: 'NoShow',
    COMPLETED: 'Completed',
    CANCELED: 'Canceled',
});

export const ApproveTaxOptimiserResultsTypes = {
    SCORE_CARD: 'scoreCard',
    ONE_PAGER: 'onePager',
};

export const TaxResultState = {
    APPROVED: 'Approved',
    GENERATED: 'Generated',
};

export const taxOptimiserCalendlyLinkAdmin =
process.env.NEXT_PUBLIC_CURRENT_ENV === envsEnum.PRODUCTION
        ? 'https://calendly.com/taxinsights/insights-call'
        : 'https://calendly.com/tech-jiraaf/45-mins-call-2';

export const SIZE_LIMIT = 400;

export const XSLM_FORMAT = '.xlsm';

export const ITR_FILE_DETAILS = {
    displayName: 'PDF, JSON',
    format: '.pdf, .json',
    size: 5120,
    sizeDisplay: '5MB'
};

export const nameSearchMinLength = 2;

export const taxOptimiserBucketName = process.env.NEXT_PUBLIC_TAX_OPTIMISER_BUCKER;